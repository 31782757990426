.card-container {
    height: 200px;
    perspective: 1000px;
    cursor: pointer;
  }
  .card-face {
    width: 100%;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.6s;
  }
  .card-container.flipped .card-face-front {
    transform: rotateY(180deg);
  }
  .card-container.flipped .card-face-back {
    transform: rotateY(0deg);
  }
  .card-face-back {
    transform: rotateY(180deg);
  }
  