.App {
  text-align: center;
  background-color: #fff;
}

.PatientApp-body {
  text-align: center;
  background-color: #fff;
  color: #000;
  margin: 0px;
  min-height: 90vh;
  /* margin-right: 200px; */
  /* margin-left: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Montserrat', sans-serif;
}

#root,
.app,
.content {
  width: 100%;
}

.app {
  display: flex;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navLink .active {
  color: "red" !important;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  margin-top: 10%;
  height: 500px;
}
.editor-class {
  /* background-color:lightgray; */
  padding: 1rem;
  border: 1px solid #ccc;
  height: 80% !important;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.menu-header {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-family: 'Montserrat', sans-serif;
}

.nav-header {
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  flex-direction: column;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;

}

.outerRow {
  background-color: #fff;
  color: #000;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.container {
  display: flex;
  align-self: flex-start;
  flex: 3;
  padding: 20;
  max-height: 700px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.text {
  color: #000;
  text-decoration: none;
}

.center {
  display: flex;
  align-self: center;
}

.centerSelf {
  align-self: center;
}

.inline {
  display: inline-block;
}

.icon {
  font-size: 30px;
  color: white;
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
a {
  cursor: pointer;
}
.App-header {
  background-color: #fff;
  min-height: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* 
.react-calendar {
  height: 350px !important;
  width: 800px !important;
  background-color: lightgreen !important;
} */

.App-body {
  background-color: #fff;
  min-height: 90vh;
  /* margin-right: 200px; */
  /* margin-left: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.input {
  width: 30vw;
  height: 5vh;
  margin: 10px 0px;
  font-size: 20px;
}

.authButton {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.link {
  cursor: pointer;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.react-calendar {
  width: 800px !important;
  background-color: white !important;
  /* width: 350px; */
  max-width: 100%;
  /* background: white; */
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  height: 100px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: left;
  line-height: 16px;
  display: flex;
  /* align-self:flex-start */
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.on-off-switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  position: relative;
  border-radius: 17px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.switch {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease-in-out;
}

.switch.on {
  left: 28px;
  background-color: #00c853;
}

.switch.off {
  background-color: #f44336;
}

.faq-page p {
  font-size: 1.2rem;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.faq-page h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight:600;
  text-align: left;
}

.faq-question {
  font-weight: bold;
  font-size: 1.2rem;
  border: 1px solid rgba(3, 46, 94, 0.06);
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.faq-answer {
  margin-bottom: 1rem;
  border-top: 1px solid rgba(3, 46, 94, 0.06);
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
}

.calendar_default_event_inner {
  background: #2e78d6;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 10pt;
  padding: 5px;
  opacity: 0.8;
}

.calendar_default_event_delete {
  display: none !important;
}
/* 
.table {
  overflow: auto;
  display: block;
  table-layout: auto;
  }

  .table-responsive {
    overflow-x: auto;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pagination {
    margin: 0;
  }
  
  .page-item.active .page-link {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .page-link {
    color: #007bff;
  }
  
  .page-link:hover {
    color: #0056b3;
  } */

.search-bar {
  margin-bottom: 20px;
}

.study-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Three equal columns */
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
  height: 100%; /* 100% of the parent container's height */
}

.study-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 158, 197, 0.1);
}

.study-card img {
  object-fit: cover;
  border-radius: 10px;
}

.study-card h2 {
  margin-top: 10px;
}

.study-card p {
  color: #666;
  font-size: 0.9em;
}

.profile {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.input-row-full {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.input-row-full div {
  width: 97.5%;
}

.input-group {
  flex: 0 0 48%;
}

.input-group div {
  width: 95%;
}

.input-group label {
  display: block;
}

.input-group input,
.input-group select {
  width: 100%;
}

.nav-link.active {
  color: #1087ff !important;
}

.nav-link {
  color: #000 !important;
}

.typeahead {
  position: relative;
  width: 300px;
}

#selectedOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
}

.selected-option {
  display: flex;
  align-items: center;
  background-color: #e1e1e1;
  border-radius: 10px;
  padding: 5px 10px;
}

.selected-option span {
  margin-left: 5px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f2f2f2;
}

.tier-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.tier-card {
  border: 2px solid;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: #fff;
  text-align: center;
}

.tier-card.selected {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tier-card h3 {
  margin-top: 0;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px 6px 0 0;
}

.tier-card hr {
  border: 1;
  height: 1px;
  background-color: #eee;
  margin: 10px 0;
}

.tier-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.braintree-hosted-field {
  height: 40px;
  border: 1px solid black;
  padding: 0 1rem;
}

.file-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  
}

.file-image,
.file-document {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.file-checkbox {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #009EC5;
}

.file-checkbox:checked {
  background-color: #009EC5; 
}

.file-checkbox input:checked {
  background-color: #4caf50; /* Change this color as needed */
}

.file-checkbox

.card-face {
  border: none;
  border-radius: none;
}

.pageBanner {
  background-color: #009EC5;
  padding: 10px;
}

.pageBanner h1 {
  color: white;
}

.tab-container {
  display: flex;
  background-color: #002e4e;
  padding: 10px;
  border-radius: 20px;
}

.tab-item {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 20px;
}

.tab-item:not(:last-child) {
  margin-right: 10px;
}

.tab-item.active {
  background-color: #00b4d8;
  color: #ffffff;
}

.MuiTablePagination-actions {
  display: flex;
  align-items: center;
}

.MuiTablePagination-actions span {
  margin: 0 10px;
  font-weight: bold;
}

.custom-card .ant-card-head {
  background-color: rgba(0, 158, 197, 0.1) !important;
}

.row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.social-login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px ;
  padding: 20px;
align-items: center;
background-color: #E1E6EF;
border: 1px solid #E1E6EF;
gap: 10px;
text-align: left;
}

.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc .fc-daygrid-day-number {
  color: #032E5E;
}

.fc .fc-col-header-cell-cushion {
  color: #032E5E;
}

.card-container{
  height: 315px !important;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-download-button,
video::-webkit-media-controls-settings-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
}

.file-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.file-name {
  margin-top: 8px;
  word-wrap: break-word;
  max-width: 150px; /* Set the maximum width for the text container */
  white-space: normal; /* Allows the text to wrap */
}
.custom-modal-content {
  min-width: 90%; /* Adjust the width as needed */
}